import React from "react";
import Banner1 from "../../components/banners/banner1/banner1";
import Banner2 from "../../components/banners/banner2/banner2";
import Courses from "../../components/courses/courses";
import Diplomat from "../../components/diplomat/diplomat";
import Footer from "../../components/footer/footer";
import Introduction from "../../components/introduction/introduction";
import Strategies from "../../components/strategies/strategies";
import styles from "./homeView.module.css";

const HomeView = () => {
  return (
    <>
      <div className={styles.container__home}>
        <div className={styles.container__home_background}>
          <Introduction />
        </div>
        <Banner1 />
        <Courses />
        <Banner2 />
        <Diplomat />
        <Strategies />
        <Footer />
      </div>

      <div className={styles.container__home_banner}></div>

      {/* <Courses /> */}
    </>
  );
};

export default HomeView;
