import React from "react";
import styles from "./banner1.module.css";

const Banner1 = () => {
  return (
    <div className={styles.banner}>
      <div className={styles.banner_text}>
        En Talentum Line te ayudamos a lograrlo a través de los cursos virtuales
        en los que te enseñamos paso a paso, los procesos necesarios para
        alcanzar el éxito dentro del ecosistema digital.
      </div>
    </div>
  );
};

export default Banner1;
