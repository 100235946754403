export const checkboxes = [
  {
    label: "Planeación de pautas digitales efectivas",
    value: "4681031826",
  },
  {
    label: "Plan de Marketing Digital",
    value: "4681031827",
  },
  {
    label: "Piezas publicitarias innovadoras para redes sociales",
    value: "4681031828",
  },
  {
    label: "E-commerce de éxito",
    value: "4681031829",
  },
  {
    label: "Definición e implementación de estrategias digitales",
    value: "4681031830",
  },
];
