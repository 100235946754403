import React from "react";
import styles from "./header.module.css";
import logo_TL from "../../../../assets/images/logo_TL.png";
import logo_alianza from "../../../../assets/images/logo_alianza.png";
import logo_alianza_mobile from "../../../../assets/images/logo_alianza_mobile.png";
import logoFB from "../../../../assets/icons/fb.png";
import logoIG from "../../../../assets/icons/ig.png";
import logoIN from "../../../../assets/icons/in.png";

const Header = () => {
  const [widthResolution, setWidthResolution] = React.useState(
    window.innerWidth
  );

  React.useEffect(() => {
    const updateWindowDimensions = () => {
      const newWidth = window.innerWidth;
      setWidthResolution(newWidth);
    };

    window.addEventListener("resize", updateWindowDimensions);

    return () => window.removeEventListener("resize", updateWindowDimensions);
  }, []);

  return (
    <div className={styles.header}>
      <div>
        <div className={styles.header__logoTL}>
          <a href="https://talentumline.edu.co/" target="_balnk">
            <img src={logo_TL} alt="logoTL" id={styles.logo} />
          </a>
        </div>
        <div>
          {widthResolution > 968 ? (
            <img src={logo_alianza} alt="logoAlianza" />
          ) : (
            <img
              src={logo_alianza_mobile}
              alt="logo_alianza_mobile"
              id={styles.logo_alianza}
            />
          )}
        </div>
      </div>
      <div className={styles.header__icons}>
        <a href="https://www.facebook.com/Linetalentum/" target="_balnk">
          <img src={logoFB} alt="facebook" className={styles.icon} />
        </a>
        <a href="https://www.instagram.com/talentumline/" target="_balnk">
          <img src={logoIG} alt="instagram" className={styles.icon} />
        </a>
        <a
          href="https://www.linkedin.com/company/talentum-line/"
          target="_balnk"
        >
          <img src={logoIN} alt="linkedin" className={styles.icon} />
        </a>
      </div>
    </div>
  );
};

export default Header;
