import React from "react";
import styles from "./banner2.module.css";

const Banner2 = () => {
  return (
    <div className={styles.banner}>
      <div className={styles.banner_text}>
        <h2>¿Buscas destacarte?</h2>
        <h1>!Esta oportunidad es para ti!</h1>
        <h3>Cursos con herramientas para aplicar directamente en tu emprendimiento</h3>
      </div>
    </div>
  );
};

export default Banner2;
