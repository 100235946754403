import React from "react";
import Header from "./header/header";
import styles from "./introduction.module.css";
import nombre_inicio from "../../../assets/images/nombre_inicio.png";
import nombre_inicio_mobile from "../../../assets/images/nombre_inicio_mobile.png";
import img_banner_inicio from "../../../assets/images/img_banner_inicio.png";

const Introduction = () => {
  const [widthResolution, setWidthResolution] = React.useState(
    window.innerWidth
  );

  React.useEffect(() => {
    const updateWindowDimensions = () => {
      const newWidth = window.innerWidth;
      setWidthResolution(newWidth);
    };

    window.addEventListener("resize", updateWindowDimensions);

    return () => window.removeEventListener("resize", updateWindowDimensions);
  }, []);

  return (
    <>
      <Header />
      <div className={styles.home}>
        <div className={styles.home__left}>
          {widthResolution > 968 ? (
            <div>
              <img src={nombre_inicio} alt="nombre_inicio" />
            </div>
          ) : (
           
              <img src={nombre_inicio_mobile} alt="nombre_inicio_mobile" />
           
          )}
          <p className={styles.home__left_text}>
            ¿Quiéres posicionar tu{" "}
            <strong>emprendimiento o aumentar las ventas de tu negocio</strong>{" "}
            en internet?
          </p>
        </div>
        <div className={styles.home__right}>
          <img src={img_banner_inicio} alt="img_banner_inicio" />
        </div>
      </div>
    </>
  );
};

export default Introduction;
