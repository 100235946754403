import React from "react";
import "./formCheck.css";

const FormCheck = (props) => {
  const { label, value, register, getValues } = props;

  const atLeastOne = () =>  getValues(`cursos`) ? true : "Selecciona mínimo un curso";

    // console.log(atLeastOne())
    
  return (
    <div className="custom-control custom-checkbox text-left my-4">
      <input
        type="checkbox"
        className="custom-control-input"
        id={value}
        value={value}
        {...register(`cursos`, {
          validate: atLeastOne,
        })}
      />
      <label className="custom-control-label text-muted" htmlFor={value}>
        {label}
      </label>
    </div>
  );
};

export default FormCheck;
