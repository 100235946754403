import { url, token } from "../consts/request";

const survey = (data) => {
  // const mappedArray = data.cursos.map((info) => {
  //   const newArray = { choice_id: info };
  //   return newArray;
  // });

  let raw = JSON.stringify({
    ip_address: "127.0.0.1",
    pages: [
      {
        id: "179166246",
        questions: [
          {
            id: "711681471",
            answers: [
              {
                text: data.nombre,
              },
            ],
          },
          {
            id: "711681472",
            answers: [
              {
                text: data.apellido,
              },
            ],
          },
          {
            id: "711681473",
            answers: [
              {
                text: data.identificacion,
              },
            ],
          },
          {
            id: "711681474",
            answers: [
              {
                text: data.correo,
              },
            ],
          },
          // {
          //   id: "711681475",
          //   answers: mappedArray,
          // },
        ],
      },
    ],
  });

  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
    body: raw,
  };
  fetch(url, requestOptions)
    .then((result) => console.log(result))
    .catch((error) => console.log(error));
};

export default survey;
