import { useEffect, useState } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import HomeView from "./app/views/homeView/homeView";
import InscriptionView from "./app/views/inscriptionView/inscriptionView";
import logoWP from "./assets/icons/wp.png";
import ModalHome from "./app/components/modal/modalHome/modalHome";
import ModalForm from "./app/components/modal/modal";
import "./App.css";

function App() {
  const [modal, setModal] = useState(false);
  const [modalSuccess, setModalSuccess] = useState(false);

  useEffect(() => {
    let timer = setTimeout(() => {
      setModal(true);
    }, 60000);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <>
      <Router>
        {modal && (
          <ModalHome
            isOpen={modal}
            modalSucces={modalSuccess}
            setModalSuccess={setModalSuccess}
            setModal={setModal}
          />
        )}
        {modalSuccess && (
          <ModalForm
            isOpen={modalSuccess}
            setModal={setModal}
            setModalSuccess={setModalSuccess}
          />
        )}
        <Switch>
          <Route path="/" exact>
            <HomeView />
          </Route>
          <Route path="/inscripcion">
            <InscriptionView setModal={setModalSuccess} modal={modalSuccess} />
          </Route>
        </Switch>
      </Router>
      <a
        href="https://api.whatsapp.com/message/YNZCJIOCRQ2EI1"
        target="_balnk"
        className="floating__button"
      >
        <img src={logoWP} alt="wp" />
      </a>
    </>
  );
}

export default App;
