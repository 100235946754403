import React from "react";
import styles from "./footer.module.css";
import logo_TL from "../../../assets/images/logo_TL.png";
import logoFB from "../../../assets/icons/fb_footer.png";
import logoIN from "../../../assets/icons/in_footer.png";
import logoIG from "../../../assets/icons/ig_footer.png";
import logo_momento_talento from "../../../assets/images/logo_momento_talento.png";
import wp_footer from "../../../assets/icons/wp_footer.png";

const Footer = () => {
  return (
    <div className={styles.footer}>
      <div className={styles.footer__links}>
        <div>
          <a href="https://talentumline.edu.co/" target="_balnk">
            <img src={logo_TL} alt="logo_TL" />
          </a>
        </div>
        <div>
          <div className={styles.footer__icons}>
            <div>
              <a href="https://www.facebook.com/Linetalentum/" target="_balnk">
                <img src={logoFB} alt="logoFB" />
              </a>
            </div>
            <div>
              <a
                href="https://www.linkedin.com/company/talentum-line/"
                target="_balnk"
              >
                <img src={logoIN} alt="logoIN" />
              </a>
            </div>
            <div>
              <a href="https://www.instagram.com/talentumline/" target="_balnk">
                <img src={logoIG} alt="logoIG" />
              </a>
            </div>
          </div>
        </div>
        <div>
          <img src={logo_momento_talento} alt="logo_momento_talento" />
        </div>
        <div>
          <a
            href="https://api.whatsapp.com/message/YNZCJIOCRQ2EI1"
            target="_balnk"
          >
            <img src={wp_footer} alt="wp_footer" />
          </a>
        </div>
      </div>
      <div className={styles.footer__terms}>
        <div>
          <strong>© Talentum Line 2020.</strong> Todos los derechos reservados
        </div>
        <div>
          <a
            href="https://talentumline.edu.co/assets/pdf/Terminos_condiciones_politicas.pdf"
            target="_balnk"
          >
            Términos, Condiciones y Políticas
          </a>
        </div>
        <div>Superintendencia de Industria y Comercio de Colombia</div>
      </div>
    </div>
  );
};

export default Footer;
