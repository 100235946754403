import React from "react";
import styles from "./strategies.module.css";
import img_estrategias_digitales from "../../../assets/images/img_estrategias_digitales.png";

const Strategies = () => {
  const [widthResolution, setWidthResolution] = React.useState(
    window.innerWidth
  );

  React.useEffect(() => {
    const updateWindowDimensions = () => {
      const newWidth = window.innerWidth;
      setWidthResolution(newWidth);
    };

    window.addEventListener("resize", updateWindowDimensions);

    return () => window.removeEventListener("resize", updateWindowDimensions);
  }, []);

  return widthResolution > 986 ? (
    <div className={styles.strategies}>
      <h1>
        ¿Por qué es importante crear estrategias <br /> digitales para tu
        negocio?
      </h1>
      <div className={styles.strategies__image}>
        <img src={img_estrategias_digitales} alt="img_estrategias_digitales" />
      </div>
      <h3>Esto sucede en internet en un minuto</h3>
      <p>
        Estimación de una selección de actividades y datos generados online en
        un minuto en 2021
      </p>
      <h5>Fuente: Lori Lewis vìa AllAccess</h5>
    </div>
  ) : null;
};

export default Strategies;
