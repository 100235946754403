import React from "react";
import styles from "./diplomat.module.css";
import img_banner_diplomado from "../../../assets/images/img_banner_diplomado.png";
import { withRouter } from "react-router";

const Diplomat = (props) => {
  const [widthResolution, setWidthResolution] = React.useState(
    window.innerWidth
  );

  React.useEffect(() => {
    const updateWindowDimensions = () => {
      const newWidth = window.innerWidth;
      setWidthResolution(newWidth);
    };

    window.addEventListener("resize", updateWindowDimensions);

    return () => window.removeEventListener("resize", updateWindowDimensions);
  }, []);

  return (
    <div className={styles.diplomat}>
      {widthResolution < 986 && (
        <div className={styles.titleMobile}>
          <h4>Realiza todos los cursos del programa</h4>
          <h2>Emprendimiento Digital</h2>
        </div>
      )}
      <div className={styles.diplomat_image}>
        <img src={img_banner_diplomado} alt="img_banner_diplomado" />
      </div>
      <div className={styles.diplomat_info}>
        <div>
          {widthResolution > 986 ? (
            <>
              <h2>Realiza todos los cursos del programa</h2>
              <h1>Emprendimiento Digital</h1>
              <h3>para obtener el certificado de:</h3>
              <div className={styles.subscribe}>
                <h2>
                  Diplomado en <br /> Emprendimiento Digital
                </h2>
                <div>
                  <a
                    className="btn btn-light"
                    href="https://pagos.talentumline.edu.co/product/diplomado-en-emprendimiento-digital/"
                  target="_balnk"
                  >
                    Inscribirme
                  </a>
                </div>
              </div>
            </>
          ) : (
            <div className={styles.subscribeMobile}>
              <h3>para obtener el certificado de:</h3>
              <h2>
                Diplomado en <br /> Emprendimiento Digital
              </h2>
              <div>
                <a
                  className="btn btn-light"
                  href="https://pagos.talentumline.edu.co/product/diplomado-en-emprendimiento-digital/"
                  target="_balnk"
                >
                  Inscribirme
                </a>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default withRouter(Diplomat);
