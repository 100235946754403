import React from "react";
import "./cardCourse.css";
import { withRouter } from "react-router";
import video from "../../../../assets/icons/video.png";

const CardCourse = (props) => {
  const { title, timeCourse, image, description, name, guest, cost, url } = props;

  return (
    <>
      <div className="col-12 col-md-6 col-lg-4 mb-1 mb-md-4 pb-5">
        <div className="card mx-auto courses__card">
          <a
            href={url}
            target="_balnk"
          >
            <div
              className="card-img-wrap"
              // onClick={() => props.history.push("/inscripcion")}
            >
              <img src={image} className="card-img-top" alt="..." />
              <img src={video} alt="video" className="card-img-video" />
            </div>
          </a>
          {/* <div className="courses__img--zoom">
            <img src={video} alt="video" />
          </div> */}
          <div className="card-body d-flex flex-column">
            <h5 className="card-title">{title}</h5>
            <h5 className="card-name">{name}</h5>
            <h5 className="card-guest">{guest}</h5>
            <p className="card-description text-muted">{description}</p>
            <div className="card-action mt-auto mb-4">
              <div className="d-flex align-items-center mb-2">
                <div className="card-time">{cost}</div>
                <div className="card-time mb-0 mx-3">
                  <strong>|</strong>
                </div>
                <div className="card-time">{timeCourse}</div>
              </div>
              <div className="card-button">
                <a
                  className="btn btn-info"
                  href={url}
                  target="_balnk"
                  // onClick={() => props.history.push("/inscripcion")}
                >
                  Inscribirme
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default withRouter(CardCourse);
