import imgCielo from '../../assets/images/cielo.png'
import imgRicardo from '../../assets/images/ricardo.png'
import imgAndres from '../../assets/images/andres.png'
import imgPaola from '../../assets/images/paola.png'
import imgValentina from '../../assets/images/valentina.png'

export const courses = [
    {
        title: "Planeación de pautas digitales efectivas",
        name: "Experta: Cielo Bernate",
        description: "Especialista en atracción web, 10 años de experiencia en implementación de estrategias digitales para el crecimiento y fidelización de audiencias en redes sociales.",
        image: imgCielo,
        cost: "$150.000",
        time: "12 hrs",
        url: "https://pagos.talentumline.edu.co/product/planeacion-de-pautas-digitales-efectivas/"
    },
    {
        title: "Plan de Marketing Digital",
        name: "Experto: Ricardo Parra",
        description: "Comunicador Social con énfasis publicitario, especialista en gerencia multimedia y digital y ejecutor de acciones de marketing digital, analista de mercadeo, creación de estrategias y planes de publicidad.",
        image: imgRicardo,
        cost: "$150.000",
        time: "12 hrs",
        url: "https://pagos.talentumline.edu.co/product/plan-de-marketing-digital/"
    },
    {
        title: "Piezas publicitarias innovadoras para redes sociales",
        name: "Experto: Andrés Lombana",
        description: "Profesional de la comunicación gráfica y docente, encargado de liderar diversos módulos sobre tipografía, retrospectiva del diseño gráfico, cartel, empaques, diseño de marcas, entre otros.",
        image: imgAndres,
        cost: "$150.000",
        time: "12 hrs",
        url: "https://pagos.talentumline.edu.co/product/piezas-publicitarias-innovadoras-para-redes-sociales/"
    },
    {
        title: "E-commerce de éxito",
        name: "Experta: Paola Córdoba",
        description: "Comunicadora social con énfasis en periodismo y marketing digital, E comerce Mananger de la tienda Vichy, seguimiento campañas SEO/SEM. / santhilari.com / Web (WordPress). Ecommerce (PrestaShop).",
        image: imgPaola,
        cost: "$150.000",
        time: "12 hrs",
        url: "https://pagos.talentumline.edu.co/product/e-commerce-de-exito/"
    },
    {
        title: "Definición e implementación de estrategias digitales",
        name: "Experta: Valentina Palacios",
        guest: "Invitado: Juan Sabogal",
        description: "Publicista con 8 años de experiencia en gestión de proyectos digitales, para reconocidas marcas.",
        image: imgValentina,
        cost: "$150.000",
        time: "12 hrs",
        url: "https://pagos.talentumline.edu.co/product/definicion-e-implementacion-de-estrategias-digitales/"
    }
]