import React from "react";
import FormCheck from "../../components/introduction/formCheck/formCheck";
import FormRow from "../../components/introduction/formRow/formRow";
import { useForm } from "react-hook-form";
import survey from "../../api/survey";
import { checkboxes } from "../../consts/form";
import ModalForm from "../../components/modal/modal";
import iconBack from "../../../assets/icons/arrow-left.png";
import { withRouter } from "react-router";
import "./inscriptionView.css";
import img_formulario_mobile from "../../../assets/images/img_formulario_mobile.png";

const InscriptionView = (props) => {
  const { modal, setModal } = props;

  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    setModal(true);
    survey(data);
  };

  const [widthResolution, setWidthResolution] = React.useState(
    window.innerWidth
  );

  React.useEffect(() => {
    const updateWindowDimensions = () => {
      const newWidth = window.innerWidth;
      setWidthResolution(newWidth);
    };

    window.addEventListener("resize", updateWindowDimensions);

    return () => window.removeEventListener("resize", updateWindowDimensions);
  }, []);

  return (
    <>
      {modal && <ModalForm isOpen={modal} />}

      <div className="inscription__container d-flex">
        <div className="back" onClick={() => props.history.push("/")}>
          <img src={iconBack} alt="back" />
        </div>
        <div className="col col-md-6 col-lg-6 inscription__background" />
        <div className="col-12 col-md-6 col-lg-6 inscription__card px-0">
          <div className="inscription__form__container text-center">
            {widthResolution < 968 && (
              <div className="background-mobile">
                <img src={img_formulario_mobile} alt="img_formulario_mobile" />
              </div>
            )}
            <h3>Programa de</h3>
            <div className="text-edigital">Emprendimiento Digital</div>
            <div className="inscription__inputs">
              <form onSubmit={handleSubmit(onSubmit)}>
                <FormRow
                  type={"text"}
                  id={"nombre"}
                  placeHolder={"Nombre"}
                  register={register}
                />
                {errors.nombre && (
                  <span className="text-danger">{errors.nombre.message}</span>
                )}
                <FormRow
                  type={"text"}
                  id={"apellido"}
                  placeHolder={"Apellido"}
                  register={register}
                />
                {errors.apellido && (
                  <span className="text-danger">{errors.apellido.message}</span>
                )}
                <FormRow
                  type={"text"}
                  id={"identificacion"}
                  placeHolder={"No. identificación"}
                  register={register}
                />
                {errors.identificacion && (
                  <span className="text-danger">
                    {errors.identificacion.message}
                  </span>
                )}
                <FormRow
                  type={"text"}
                  id={"correo"}
                  placeHolder={"Correo"}
                  register={register}
                  patternValue={/\S+@\S+\.\S+/}
                  patternMessage={
                    "El valor ingresado no coincide con el formulario de correo electrónico"
                  }
                />
                {errors.correo && (
                  <span className="text-danger">{errors.correo.message}</span>
                )}
                {/* <FormRow
                  type={"text"}
                  id={"celular"}
                  placeHolder={"Número de celular"}
                  register={register}
                />
                {errors.celular && (
                  <span className="text-danger">{errors.celular.message}</span>
                )} */}
                <div className="division" />
                <h4 className="mt-4">Selecciona los cursos que deseas tomar</h4>
                {checkboxes.map((field, index) => (
                  <FormCheck
                    label={field.label}
                    value={field.value}
                    register={register}
                    getValues={getValues}
                    key={field.value}
                    index={index}
                  />
                ))}

                {errors.cursos && (
                  <span className="text-danger">{errors.cursos.message}</span>
                )}
                <button
                  className="btn inscription__form--button w-100"
                  type="submit"
                  //   onClick={() => console.log(errors)}
                >
                  Inscribirme
                </button>
              </form>
              <p className="form__terms mb-5 mb-md-3">
                Acepto{" "}
                <a
                  className="form__terms link"
                  href="https://talentumline.edu.co/assets/pdf/Terminos_condiciones_politicas.pdf"
                  target="_balnk"
                >
                  Terminos y Condiciones
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default withRouter(InscriptionView);
