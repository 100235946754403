import React from "react";
import IconCheckBig from "../../../assets/icons/checkBig.svg";
import { Modal, ModalBody } from "reactstrap";
import { withRouter } from "react-router";
import "./modal.css";

const ModalForm = (props) => {
  const closeModals = React.useCallback(() => {
    props.setModal(false);
    props.setModalSuccess(false);
  }, [props]);

  React.useEffect(() => {
    let timer = setTimeout(() => {
      closeModals();
    }, 2500);

    return () => {
      clearTimeout(timer);
    };
  }, [closeModals]);

  return (
    <Modal
      isOpen={props.isOpen}
      className="modal-dialog modal-dialog-centered modal-lg modal-form"
    >
      <ModalBody>
        <div className="text-center py-3">
          <img
            src={IconCheckBig}
            width="99.2"
            height="99.2"
            className="mb-1"
            alt="check"
          />
          <p className="message-modal tex-center px-4">
            Se ha enviado correctamente
          </p>
        </div>
      </ModalBody>
      {/* <ModalFooter className="modal-footer row justify-content-center">
        <Button className="btn-modal" onClick={() => closeModals()}>
          Aceptar
        </Button>
      </ModalFooter> */}
    </Modal>
  );
};

export default withRouter(ModalForm);
