import React from "react";
import CardCourse from "./cardCourse/cardCourse";
import styles from "./courses.module.css";
import { courses } from "../../consts/infoCourses";
import img_fondo_cursos from "../../../assets/images/img_fondo_cursos.png";
import { withRouter } from "react-router";

const Courses = (props) => {
  const [widthResolution, setWidthResolution] = React.useState(
    window.innerWidth
  );

  React.useEffect(() => {
    const updateWindowDimensions = () => {
      const newWidth = window.innerWidth;
      setWidthResolution(newWidth);
    };

    window.addEventListener("resize", updateWindowDimensions);

    return () => window.removeEventListener("resize", updateWindowDimensions);
  }, []);

  return (
    <div className={styles.courses}>
      <div className="text-center mt-4 pt-2">Portafolio Académico</div>
      <div className="text-center mb-5">Programa de Emprendimiento Digital</div>
      <div className={"container-fluid " + styles.container__courses}>
        <div className="row justify-content-center">
          {courses.map((data, index) => (
            <CardCourse
              key={index}
              title={data.title}
              name={data.name}
              guest={data.guest}
              description={data.description}
              timeCourse={data.time}
              image={data.image}
              cost={data.cost}
              url={data.url}
            />
          ))}
        </div>
        <div className={styles.offer}>
          <div>
            <h1>!Una oferta especial para ti!</h1>
            <div className={styles.offer__container}>
              <div>
                <div>1 curso x $150.000</div>
                <div>2 cursos x $280.000</div>
              </div>
              <div>
                <div>3 cursos x $390.000</div>
                <div>4 cursos x $480.000</div>
              </div>
              <div>5 cursos x $500.000</div>
            </div>
          </div>
          <div className={styles.offer__button}>
            <a
              className="btn btn-light"
              href="https://pagos.talentumline.edu.co/emprendimiento/"
              target="_balnk"
            >
              Escoge tu pack aquí
            </a>
          </div>
        </div>
      </div>
      {widthResolution > 968 ? (
        <div className={styles.img__fondo}>
          <img src={img_fondo_cursos} alt="img_fondo_cursos" />
        </div>
      ) : null}
    </div>
  );
};

export default withRouter(Courses);
