import React from "react";
import { Modal, ModalBody } from "reactstrap";
import { withRouter } from "react-router";
import { useForm } from "react-hook-form";
import "./modalHome.css";
import FormRow from "../../introduction/formRow/formRow";
import iconClose from "../../../../assets/icons/close.png";
import survey from "../../../api/survey";

const ModalHome = (props) => {
  const { setModalSuccess, setModal } = props;

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    survey(data);
    setModalSuccess(true);
    console.log(data);
  };
  return (
    <Modal
      isOpen={props.isOpen}
      className="modal-dialog modal-dialog-centered modal-lg modal-home"
    >
      <ModalBody>
        <div
          style={{ position: "absolute", right: "0" }}
          onClick={() => setModal(false)}
        >
          <img src={iconClose} alt="iconClose" className="modal-close" />
        </div>
        <div className="text-center container-text">
          <h2 className="title-modal-home tex-center px-4">
            !Nos visitaste rápidamente!
          </h2>
          <p className="message-modal-home tex-center px-4">
            Te enviaremos un código <br /> de descuento
          </p>
        </div>
        <div className="inscription__inputs-home">
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormRow
              type={"text"}
              id={"nombre"}
              placeHolder={"Nombre"}
              register={register}
            />
            {errors.nombre && (
              <span className="text-danger">{errors.nombre.message}</span>
            )}
            <FormRow
              type={"text"}
              id={"apellido"}
              placeHolder={"Apellido"}
              register={register}
            />
            {errors.apellido && (
              <span className="text-danger">{errors.apellido.message}</span>
            )}
            <FormRow
              type={"text"}
              id={"correo"}
              placeHolder={"Correo"}
              register={register}
              patternValue={/\S+@\S+\.\S+/}
              patternMessage={
                "El valor ingresado no coincide con el formulario de correo electrónico"
              }
            />
            {errors.correo && (
              <span className="text-danger">{errors.correo.message}</span>
            )}
            <FormRow
              type={"text"}
              id={"identificacion"}
              placeHolder={"Teléfono"}
              register={register}
            />
            {errors.identificacion && (
              <span className="text-danger">
                {errors.identificacion.message}
              </span>
            )}
            {/* <FormRow
                  type={"text"}
                  id={"celular"}
                  placeHolder={"Número de celular"}
                  register={register}
                />
                {errors.celular && (
                  <span className="text-danger">{errors.celular.message}</span>
                )} */}

            {errors.cursos && (
              <span className="text-danger">{errors.cursos.message}</span>
            )}
            <button
              className="btn inscription__home--button w-100 mt-2"
              type="submit"
              //   onClick={() => console.log(errors)}
            >
              Inscribirme
            </button>
          </form>
          <p className="form__terms mb-5 mb-md-3">
            Acepto{" "}
            <a
              className="form__terms link"
              href="https://talentumline.edu.co/assets/pdf/Terminos_condiciones_politicas.pdf"
              target="_balnk"
            >
              Terminos y Condiciones
            </a>
          </p>
        </div>
      </ModalBody>
    </Modal>
  );
};
export default withRouter(ModalHome);
